import { useEffect, useRef, useState } from 'react'
import { Object3D, Color, Box3, Vector3, Clock, sRGBEncoding, MeshBasicMaterial } from 'three'
import arService from '../../../services/arService'
import debugService from '../../../services/debugService'
import interactionService from '../../../services/interactionService'
import { interactive } from '../../../services/storyService'
import AnimateObject from '../animateObject'
import Icon from '../icon'
import PointOfInterest from '../pointOfInterest'

interface UpgradeObject {
  objects: Array<Object3D>
  data: interactive
}

const ANIM_HOVER_SPEED = 0.35
const ANIM_HOVER_HEIGHT = 0.075

const UpgradeObject: Object3D<UpgradeObject> = ({objects, data}) => {
  const handleInteractionClick = event => {
    if(interactionService.getState().mode !== "INTERACT") return

    if(level < maxLevel)
      setTimeout(() => {setLevel(level + 1)}, 1000)

    interactionService.getState().setInteraction(data.id)
    interactionService.getState().setSelected(data.id)
  }

  const getGroupByLevel = (level: number) => {
    const object: Object3D = objects.filter(object => level === object.userData.level)[0]

    if(!object) {
      console.error(`UpgradeObject::return(): No object for level = ${level} found!`)
      return <></>
    }

    object.material = new MeshBasicMaterial({map: object.material.map})
    object.material.map.encoding = sRGBEncoding

    // console.log("selected object =", object, "for level =", level)

    const animateObjects: Array<Object3D> = object.children.filter(child => child.userData.type === "animate")
    const iconObjects: Array<Object3D> = object.children.filter(child => child.userData.type === "icon")
    object.children = []

    const hitboxSize: Vector3 = new Vector3()
    const boundingBox: Box3 = new Box3().setFromObject(object);
    boundingBox.getSize(hitboxSize)
    hitboxSize.setComponent(1, hitboxSize.y * 0.3)

    return (
      <group position={object.position.toArray()} ref={groupRef}>
        <PointOfInterest id={data.id} yOffset={hitboxSize.y * 3.0} text={data.title}/>
        <mesh visible={debugService.getState().debugEnabled} onClick={handleInteractionClick}>
          <boxBufferGeometry args={hitboxSize.toArray()}/>
          <meshBasicMaterial color={new Color(0xff00ff)} wireframe={true}/>
        </mesh>
        <primitive object={object} position={[0.0, 0.0, 0.0]}>
          {animateObjects.map(object => <AnimateObject key={object.id} object={object}/>)}
          {/*iconObjects.map(object => <Icon key={object.id} object={object}/>)*/}
        </primitive>
      </group>
    )
  }

  const [required] = useState<boolean>(objects[0].userData.required)

  const [level, setLevel] = useState<number>(0)
  const [maxLevel] = useState<number>(objects.length - 1)

  const [group, setGroup] = useState<Object3D | null>(null)
  const [clock] = useState<Clock>(new Clock())
  const [randomOffset] = useState<number>(4.0 * Math.PI * Math.random())
  const groupRef = useRef<Object3D>()

  useEffect(() => {
    requestAnimationFrame(animate)
  }, [])

  const animate = () => {
    requestAnimationFrame(animate)

    if(!groupRef.current) return

    groupRef.current.position.setComponent(1, ANIM_HOVER_HEIGHT + (Math.cos(randomOffset + clock.getElapsedTime() * ANIM_HOVER_SPEED) + 1.0) * 0.5 * ANIM_HOVER_HEIGHT)
  }

  useEffect(() => {
    if(!required) return
    interactionService.getState().registerRequiredInteraction(data.id)
  }, [data.id])

  useEffect(() => {
    setGroup(getGroupByLevel(level))
  }, [level])

  return group ? group : <></>
}

export default UpgradeObject